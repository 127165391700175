<template>
  <div class="inside-page-content">
    <div class="paper light-grey-bg">
      <div class="block">
        <div class="top-search">
          <top-filter :config="{friends: true}" @handleSearchParams="handleSearch"></top-filter>
        </div>
        <div class="top-search">
          <top-filter :config="{atArea: true}" @handleSearchParams="handleSearch2"></top-filter>
        </div>
        <div class="pro-list">
          <div class="item" v-for="(t, i) in dataList" :key="i">
            <div class="img-box">
              <img :src="t.pictureUrl" class="img" />
            </div>
            <div class="product">
              <div class="name">{{t.unitName}}</div>
              <span class="class">{{types[t.unitType]}}</span>
              <div class="at">{{location[t.unitLocation]}}</div>
              <div class="desc">{{t.introduction}}</div>
            </div>
            <div class="more"><a href="" style="color: #fff">查看详情</a></div>
          </div>
          <div class="footer">
            <div class="left">共 {{total}} 条</div>
            <el-pagination
              background
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page.sync="page.pageNum"
              layout="prev, pager, next"
              :page-size="6"
              :total="total"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopFilter from '../../components/topFilter.vue'
export default {
  name: 'market',
  components: {
    TopFilter
  },
  data() {
    return {
      current: 0,
      keyword: "",
      dataList: [
        {
          name: '长江证券绵阳花园南街营业部',
          desc: '主要经营业务范围 证券经纪、证券投资咨询、融资融券证券投资基金代销、代销金融产品...',
          imgUrl: require('../../static/images/share/f2.png'),
          id: 2
        },
        {
          name: '红塔证券绵阳临园路营业部',
          desc: '主要经营业务范里 证券经纪： 证券投资咨询，与证券交易、证卷投资活动有关的财务顾问...',
          imgUrl: require('../../static/images/share/f1.png'),
          id: 1
        },
        {
          name: '国海证券绵阳滨江路营业部',
          desc: '主要经营业务范里 证券经纪： 证券投资咨询，与证券交易、证卷投资活动有关的财务顾问...',
          imgUrl: require('../../static/images/share/f3.png'),
          id: 3
        },
        {
          name: '安信证券绵阳绵兴东路营业部',
          desc: '主要经营业务范围 证券经纪、证券投资咨询、融资融券证券投资基金代销、代销金融产品...',
          imgUrl: require('../../static/images/share/f5.png'),
          id: 5
        },
        {
          name: '中信证券绵阳绵兴东路营业部',
          desc: '主要经营业务范围 证券经纪、证券投资咨询、融资融券证券投资基金代销、代销金融产品...',
          imgUrl: require('../../static/images/share/f4.png'),
          id: 4
        },
        {
          name: '英大证券绵阳体运村路营业部',
          desc: '主要经营业务范围 证券经纪、证券投资咨询、融资融券证券投资基金代销、代销金融产品...',
          imgUrl: require('../../static/images/share/f6.png'),
          id: 6
        }
      ],
      page: {
        pageNum: 1,
        pageSize: 6
      },
      queryInfo: {
        keywords: null
      },
      total: null,
      location: {
        1: '西南',
        2: '西北',
        3: '华中',
        4: '华东',
        5: '华南',
        6: '华北',
        7: '东北'
      },
      types: {
        1: '军工单位',
        2: '科研院所',
        3: '大型企业',
        4: '高等院校',
        5: '高新技术企业',
        6: '医疗机构',
        7: '检测机构',
        8: '军民融合企业',
        9: '政府机构',
        10: '民营企业'
      }
    }
  },
  computed: {
  },
  created() {
    this.getList();
  },
  mounted() {

  },
  methods: {
    handleSearch(params) {
      // 发起请求
      this.queryInfo.type = params.friends
      this.getList();
    },
    handleSearch2(params) {
      // 发起请求
      this.queryInfo.location = params.atArea
      this.getList();
    },
    getList() {
      let params = Object.assign(this.page, this.queryInfo)
      this.$api.share.unit(params).then((res) => {
        let data = res.data;
        this.dataList = data.rows
        this.total = data.total
      })
    },
    handleCurrentChange(val) {
      this.page.pageNum = val;
      this.getList();
    },
    handleSizeChange(val) {
      this.page.pageSize = val;
      this.getList();
    },
    navTo(id) {
      this.$router.push({ path: '/sharedInstrument/fDetail', query: { id: id } })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.top-search {
  background: #fff;
  padding: 0 40px 20px;
  .form-item {
    display: flex;
    align-items: center;
    padding-bottom: 20px;
    .search {
      width: 300px;
      margin-left: 30px;
    }
  }
}
.pro-list {
  background: #fff;
  margin-top: 40px;
  padding: 30px;
  .item {
    padding: 20px 10px;
    box-sizing: border-box;
    border-bottom: 1px solid #ececec;
    display: flex;
    position: relative;
    .img-box {
      width: 200px;
      height: 138px;
      .img {
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
      }
    }
    .product {
      flex: 1;
      padding: 0 130px 0 40px;
      .name {
        font-weight: 700;
        padding-bottom: 8px;
        font-size: 18px;
      }
      .desc {
        text-indent: 40px;
        font-size: 14px;
        height: 40px;
      }
      .class {
        background-color: #990000;
        color: #fff;
        font-size: 12px;
        padding: 3px 6px;
      }
      .at {
        padding: 8px 0;
        font-size: 12px;
      }
    }
    .more {
      position: absolute;
      top: 50%;
      right: 20px;
      transform: translateY(-50%);
      background-color: #0046c0;
      color: #fff;
      padding: 5px 10px;
      border-radius: 6px;
      margin: 0 20px 20px;
      cursor: pointer;
      font-size: 14px;
    }
  }
}
</style>
